<template>
  <div>
    <div 
      class="col-sm-6 mx-auto stepper"
    >
      <div class="steps">
        <div 
          v-for="(item, index) in stepData" :key="item"
          class="step"
          :class="{
            'completed': step > (index + 1)
          }"
        >
          <div class="circle-progress">
            <svg 
              width="27" 
              height="33" 
              viewBox="0 0 27 33" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                fill="#ccc" 
                :class="{
                  'active': step >= (index + 1)
                }" 
                d="M15.0035 32.8028C10.6635 33.3411 7.35163 32.78 4.87895 31.0869C2.45383 29.4282 0.942015 26.776 0.256322 22.9821C-0.95539 16.2753 2.50535 9.07589 2.81621 8.44844C6.18182 1.16901 9.90812 0.173209 10.9698 0.0405122L11.0179 0.0347922L11.066 0.0290739C12.1288 -0.103051 15.9837 -0.0469978 20.993 6.19375C21.446 6.72682 26.5278 12.8669 26.9587 19.6705C27.2022 23.5192 26.3704 26.4609 24.4147 28.6635C22.4222 30.9113 19.3431 32.2658 15.0035 32.8028Z"
              ></path>
              <text 
                fill="#000" 
                x="50%" 
                y="50%" 
                text-anchor="middle" 
                dominant-baseline="middle" 
                font-weight="bold" 
                font-size="16"
                v-if="step <= (index + 1)"
              >
                {{(index + 1)}}
              </text>

              <svg 
                aria-label="Speed complete" 
                width="15" 
                height="13" 
                viewBox="0 0 15 13" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg" 
                x="5" 
                y="11"
                v-if="step > (index + 1)"
              >
                <path d="M1 7.25L5.55 11L14 1" stroke="#05010A" stroke-width="2.40483"></path>
              </svg>
            </svg>
          </div>
          <div class="text">
            {{item}}
          </div>
        </div>
      </div>
    </div>
    <div>
      <router-view :key="$route.path" />
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      step: 0,
      stepData: [ "Speed", "Contract", "Info", "Payment"]
    }
  },
  created(){
    this.setStep();
  },
  updated(){
    this.setStep();
  },
  methods: {
    setStep: function(){
      if(this.$route.name === 'dashboard-cuckoo-speed'){
      this.step = 1;
      }
      if(this.$route.name === 'dashboard-cuckoo-contract'){
        this.step = 2;
      }
      if(this.$route.name === 'dashboard-cuckoo-info'){
        this.step = 3;
      }
      if(this.$route.name === 'dashboard-cuckoo-payment'){
        this.step = 4;
      }
    }
  }

}
</script>

<style lang="scss">
  .stepper {
    margin: 0 10%;
    width: 80%;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    .active{
      fill: #FFE802;
    }
    .steps {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100%);
      margin: auto;
      position: relative;
      div.text{
        color: #000;
      }
      .step{
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:last-of-type {
          .circle-progress::after {
            display: none;
          }
        }
        .circle-progress {
          z-index: 2;
          margin: 10px;
          &::after {
            display: block;
            content: '';
            position: absolute;
            height: 5px;
            background-color: #E1E4E8;
            top: 22px;
            margin-left: 28px;
            width: calc(25% - 13px);
          }
        }
        div.text {
          font-size: 14px;
        }
      }
      .step.completed {
        .circle-progress {
          &::after {
            background-color: #FFE802;
          }
        }
      }
    }
  }
</style>